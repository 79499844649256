<template>
  <div>
    <b-card>
      <div class="mb-5">
        <b-card-title
          class="d-flex align-items-center"
        >
          <feather-icon
            icon="UserIcon"
            size="21"
            class="mr-1"
          />
          Ссылки на соц сети
        </b-card-title>
        <b-form-group
          v-for="(link, indexLink) in links"
          :key="indexLink"
          :label="link.type"
          :label-for="link.type"
        >
          <b-form-input
            :id="link.type"
            v-model="link.url"
            :name="link.type"
            :placeholder="link.type"
          />
          <small
            v-show="errors.has(link.type)"
            class="text-danger"
          >{{ errors.first(link.type) }}</small>
        </b-form-group>
        <button
          type="button"
          class="btn btn-primary"
          @click="changeLinks(links)"
        >
          Сохранить
        </button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BCard,
    BCardTitle,
    BFormGroup,
    BFormInput,
  },
  computed: {
    ...mapGetters({
      links: 'masters/getLinks',
    }),
  },
  async mounted() {
    await this.fetchLinks()
  },
  methods: {
    ...mapActions({
      fetchLinks: 'masters/fetchLinks',
      changeLinks: 'masters/changeLinks',
    }),
  },
}
</script>
